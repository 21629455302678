<template>
  <div class="home">
    <div class="container-home principal">
      <div class="display-row">
        <div  class= "box-fixed-left">
          <h1>Dry</h1> 
          <h4>moments</h4>
          <h1>and</h1> 
          <h3>drinks</h3>
          <div class="icons">
            <img width="40px" src="../assets/img/laranja.png">
            <img width="40px" src="../assets/img/taca.png"  style="margin-bottom: 7%">
          </div>
        </div>
        <div class="home-text">
          Você foi convidado para nosso bar secreto
        </div>
      </div>
      <div class="display-row descubra-home">
        descubra
      </div>
    </div>

    <div class="container descubra">
      <div style="height: 10vh"></div>
      <img width="100%" src="../assets/img/DryMomentsEDrinks.png" style="max-height: 300px; max-width: 320px">
      <p class="content">
        Neste bar você terá a oportunidade de ...
      </p>
    </div>

    <div class="container dica">
      <div style="height: 10vh"></div>
      <h1 class="title">Dica</h1>
      <p class="content">
        Vá até o fundo do bar, olhe para direita e encontre uma
        geladeira de Heineken com um QR code
      </p>
    </div>

    <div class="container qr">
      <div style="height: 10vh"></div>
      <h1 class="title" style="font-size: 1.9rem; max-width: 350px">
        Aponte o visor abaixo para o QR Code e abra a porta secreta 
      </h1>
      <qrcode-stream
        @decode="onDecode"
        style="width: 75vw; height: 75vw; margin: 4vh 0"
      ></qrcode-stream>
    </div>
    <div class="leitorQR" style="height: 7vh"></div>

    <div
      class="arrow up"
      v-show="this.secaoAtiva != 0"
      @click="praCima"
      style="transform: scaleY(-1); top: 15px; padding-top: 25px"
    >
      <img src="../assets/img/arrow2.png" width="50px" />
    </div>

    <div
      class="arrow down"
      v-show="this.secaoAtiva < this.arrSecoes.length - 1"
      @click="praBaixo"
      style="bottom: 85px; padding-top: 25px"
    >
      <img src="../assets/img/arrow2.png" width="50px" />
    </div>

    <div id="nav">
      <img
        @click="scrollToElm(0)"
        src="../assets/img/Home.svg"
        height="33"
        alt=""
      />
      <img
        @click="scrollToElm(2)"
        src="../assets/img/Lupa.svg"
        height="35"
        alt=""
      />
      <img
        @click="scrollToElm(3)"
        src="../assets/img/Lock.svg"
        height="35"
        alt=""
      />
      <img src="../assets/img/Hnk.svg" width="92" alt="" />
    </div>

    <div v-if="dialog">
      <div class="fundo"></div>
      <div class="dialog">
        <h1 style="font-size: 1.7rem;">Porta liberada!</h1>
        <span @click="dialog = false">Confirmar</span>
      </div>
    </div>
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");

.fundo {
  background: #000000c7;
  width: 110vw;
  height: 205vw;
  position: fixed;
  bottom: 0;
  z-index: 3;
}

.dialog {
  background-color: white;
  height: 40vh;
  width: 70vw;
  border-radius: 25px;
  position: fixed;
  top: 25%;
  left: 15%;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.dialog span {
  color: #1e88e5;
  font-weight: 600;
}

.dialog::before {
  background-color: black;
  width: 100vw;
  height: 100vw;
  position: fixed;
}

.container-home {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.container {
  height: calc(100vh - 70px);
  width: 70vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.display-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.box-fixed-left {
  display: flex;
  flex-direction: column;
  font-family: Quicksand;
  text-transform: uppercase;
  line-height: 35px;
  background-color: #842e00;
  color: #fec062;
  padding-top: 50px;
  height:calc(50vh - 51px);
  width: 40vw;
  border-right: 2px solid black;
  border-bottom: 1px solid black;
  margin-right: calc(10vw - 2px);
}

.box-fixed-left h1 {
  padding: 0px 8px;
  margin: auto;
  text-align: center;
  font-family: Quicksand-Medium;
  font-size: 3.0rem;
  letter-spacing: 0.3em;
}

.box-fixed-left h3 {
  text-align: center;
  padding: 0px 8px;
  margin: auto;
  font-size: 1.45rem;
  letter-spacing: 0.4em;
}

.box-fixed-left h4 {
  text-align: center;
  padding: 0px 8px;
  margin: auto;
  font-size: 1.2rem;
  letter-spacing: 0.3em;
}

.icons {
  display: flex;
  height: 100% ;
  justify-content: space-around;
  align-items: flex-end;
  padding: 0 15%;
  padding-bottom: 20%;
}

.home-text {
  display: flex;
  color: white;
  padding-right: 20px;
  align-items: flex-end;
  text-align: initial;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 30px;
  width: calc(50vw - 20px);
  height: 50vh;
}

.descubra-home {
  display: relative;
  color: #ffd966;
  align-items: flex-end;
  justify-content: center;
  font-family: "Lato", sans-serif;
  text-shadow: 0px 0px 4px #ff0000;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 30px;
  padding-bottom: 90px;
  height: calc(50vh - 70px - 90px);
}

.title_home {
  font-family: "Roboto", sans-serif;
  font-weight: 200;
  color: #fff;
  margin: 0;
}

.title {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 2.7rem;
  color: #ffd966;
  text-transform: uppercase;
  text-shadow: 0px 0px 4px #ff0000;
  margin: 0;
}

.content {
  font-size: 2rem;
  font-weight: 300;
  width: 100%;
  color: #fff;
  text-align: center;
  margin-bottom: 0;
  text-align:left;
}

.subTitle {
  font-size: 1.7rem;
  font-weight: 300;
  width: 80vw;
  color: #fff;
  text-align: center;
  margin: 0;
}

.qr img {
  margin-bottom: 5vh;
}

.arrow {
  position: fixed;
  z-index: 3;
  right: 0;
  left: 0;
}

#nav {
  height: 70px;
  position: fixed;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 2;
}

@media (max-width: 375px) {
  .title {
    font-size: 2.3rem;
  }
  .content {
    font-size: 1.8rem;
    margin-bottom: 0;
  }
  .subTitle {
    font-size: 1.5rem;
    margin-top: 25px;
  }
  .subTitleQR {
    font-size: 8vw;
    margin: 0;
  }
  .qr img {
    margin-bottom: 7vh;
  }
}

</style>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      secaoAtiva: 0,
      arrSecoes: ["principal", "descubra", "dica", "leitorQR"],
      dialog: false,
    };
  },
  methods: {
    onDecode(decodedString) {
      if (decodedString == "https://dry.cyma.digital/?porta") { 
        let self = this
        fetch("https://hnk00.cyma.digital/scan-porta/8").then(function(res) { 
          self.dialog = true
          // alert("Porta liberada!")
        })
        
      }
    },
    praBaixo() {
      this.secaoAtiva =
        this.secaoAtiva == this.arrSecoes.length - 1
          ? this.arrSecoes.length - 1
          : (this.secaoAtiva = this.secaoAtiva + 1);
      this.scrollToElement();
    },

    praCima() {
      this.secaoAtiva = this.secaoAtiva == 0 ? 0 : --this.secaoAtiva;
      this.scrollToElement();
      if (this.secaoAtiva == 0) {
        this.$emit("scrollToTop");
      }
    },
    scrollToElement() {
      var el = this.$el.getElementsByClassName(
        this.arrSecoes[this.secaoAtiva]
      )[0];

      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
    scrollToElm(count) {
      this.secaoAtiva = count;
      var el = this.$el.getElementsByClassName(this.arrSecoes[count])[0];

      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
  },
};
</script>
