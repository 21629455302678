import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueQrcodeReader from 'vue-qrcode-reader'

Vue.config.productionTip = false
Vue.use(VueQrcodeReader);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')